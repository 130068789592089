
body{
  /*background-color: white;*/
}

/* scorll */
  ::-webkit-scrollbar {
    width: 1.1rem;
    height: 1.1rem;
  }
  ::-webkit-scrollbar-corner {
    
  }
  ::-webkit-scrollbar-thumb {
    border: 6px solid transparent;
    background: rgba(0, 0, 0, 0.2);
    background: var(--palette-black-alpha-20, rgba(0, 0, 0, 0.2));
    border-radius: 10px;
    background-clip: padding-box;
  }
 ::-webkit-scrollbar-thumb:hover {
    border: 4px solid transparent;
    background: rgba(0, 0, 0, 0.3);
    background: var(--palette-black-alpha-30, rgba(0, 0, 0, 0.3));
    background-clip: padding-box;
  }
  /* scorll */
  .webpage{
    width: 100%;
    /*background-image: url('teambg.png');*/
    background-size: cover;
    
    animation: bodyfadeInAnimation ease 3s;
    animation-iteration-count: 1;
    animation-fill-mode: forwards;
  }
  @keyframes bodyfadeInAnimation {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
  }
.webbody {
    
}

.webmenu  {
  position: fixed;
  margin-left: 4vw;
  margin-top: 14px;
  list-style-type: none;
  
  padding: 0;
  overflow: hidden;
  background-color: white;
  transition: all ease-in-out .5s;
    
  }
  
  .webmenu li {
     float: right;
  }
  
  .webmenu li a {
    display: block;
    color: #dddddd;
    text-align: center;
    padding: 14px 16px;
    text-decoration: none;
    transition: all 0.3s ease-in-out;
    margin-left: 5px;
    margin-top: 5px;
    margin-bottom: 5px;
    border-radius:0.2em;
  }
  .webmenu
  {
    background-color: white;
    opacity: 1;
  }
  .webmenu li {
    margin-right: 10px;
  }
  .webmenu li a:hover:not(.active) {
    
    color:black;
  }
  
  
  .webmenu li div .underline {
    position: relative;
    top:-55px;
    width: 0%;
    margin: 0 auto;
    height: 1.8px;
    background-color: #dddddd;
    transition: width ease-out 0.5s;
  }
  .webmenu li a:hover ~ .underline {
    width: 100%;
    background-color: black!important;
  }
  
  .active {
    /*width: 100%!important;*/
  }
  html:not([data-scroll='0']) .underline {
  
    background-color: black!important;
  }
  .psection
  {
    background: rgba(100, 100, 100, 0.05);
    
    border-radius: 0.2em;
    margin-top: 10px;
    margin-bottom: 50px;
    margin-left: 50px;
    margin-right: 50px;
    width: 50vw;
  }
  .psection div{
    color: white;
    padding: 10px;
  }
  
  .psection div p{
    font-size: medium;
    font-family: "Segoe UI", "Roboto", "Oxygen", "Ubuntu",
    "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
    padding: 10px;
    color: black;
  }

.webheading3
{
  font-family: "Segoe UI", "Roboto", "Oxygen", "Ubuntu",
  "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  margin-left: 50px;
  text-align: left;
  
  font-size: 55px;
  padding-top: 35vh;
  padding-bottom: 0px;
  text-align: center;
  transition: all ease-in-out .5s;
  background: linear-gradient(to bottom, #3b006f, #3b006f);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  color: transparent;
  
}
.webheadingcon {
    position: sticky;
    top: -35vh;
    background-color: white;
    margin-bottom: 60vh;
    z-index: 1000;
}
.webheading4
{
  font-family: "Segoe UI", "Roboto", "Oxygen", "Ubuntu",
  "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  position:relative;
  text-align: left;
  color: rgba(0, 0, 0, 1);;
  font-size: 45px;
  
  top:100px;
  z-index: 10;
  
}
.webheading4Right
{
  text-align: right;
  right:calc(15vw - 100px);
  white-space: nowrap;
}
.webheading4Left
{
  text-align: left;
  left: 100px;
  width: 80vw;
  white-space: nowrap;
}
.subwebheading4{
  font-family: "Segoe UI", "Roboto", "Oxygen", "Ubuntu",
  "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  
  color: rgba(0, 0, 0, 1);;
  font-size: large;
 
 
}
.webfooter
{
  height: 50px;
}
.webhrline
{
  border: 0.9px solid rgba(0, 0, 0, 1);
  width: 43vw;
  margin-left: 30vw;
  transition: all ease-in-out .5s;
}
html:not([data-scroll='0']) .webhrline {
  
  width: 92vw;
  margin-left: 4vw;
}
html:not([data-scroll='0']) .webmenu li a{
  
  color: black;
  
}
html:not([data-scroll='0']) .webmenu{
  
  color: black;
 
}
html:not([data-scroll='0']) .webheading3 {
  margin-left:calc(92vw - 600px);
  margin-right: 4vw;;
  width: auto;
  background: linear-gradient(to bottom, #3b006f, #9494f8);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  color: transparent;
}




header {
	width: 100%;
  min-height: 3em;
  background-color: #eee;
}
/* DivTable */
.divTable{
	display: table;
	width: 85vw;
  margin-left: 100px;
  margin-top: 100px;
  z-index: 2;
}
.divTableRow {
	display: table-row;
}

.divTableCell, .divTableHead {
	border: 0px solid #999999;
	display: table-cell;
	padding: 3px 10px;
  width: 40vw;
  max-width: 40vw;
  vertical-align: middle;
}
.divTableHeading {
	background-color: #EEE;
	display: table-header-group;
	font-weight: bold;
}
.divTableFoot {
	background-color: #EEE;
	display: table-footer-group;
	font-weight: bold;
}
.divTableBody {
	display: table-row-group;
}
.divTableCell.cellleft
{
  vertical-align: middle;
  
}

/* DivTable Contact */
.divTableContact{
	display: table;
	width: 45vw;
  margin:auto;
}
.divTableContactRow {
	display: table-row;
}

.divTableContactCell, .divTableContactHead {
	border: 0px solid #999999;
	display: table-cell;
	padding: 3px 10px;
  width: 40vw;
}
.divTableContactHeading {
	background-color: #EEE;
	display: table-header-group;
	font-weight: bold;
}
.divTableContactFoot {
	background-color: #EEE;
	display: table-footer-group;
	font-weight: bold;
}
.divTableContactBody {
	display: table-row-group;
}
.divTableContactCell.cellleft
{
  vertical-align: middle;
  
}


.mailto{
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu",
    "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  font-size: small;
}
.contactarea {
  margin-bottom: 70vh;
  margin-top: -50vh;
}
.termsarea {
  margin: 10vw;
}
.termsarea h1{ 
  color: black;
}
.bullet{ 
  margin-left: 20px;
}
.bullet2{ 
  margin-left: 40px;
}
.privacyarea {
  margin: 10vw;
}
.privacyarea h1{
  color: black;
}
.privacyarea h2{
  color: black;
}

.webpageimg
{
  width: 100%;
  height: 100%;
  filter: drop-shadow(-5px 5px 10px #444444);
}
.cellright
{
  padding-left: 5vw;
}
.getstartedarea{
  
  margin: 10vw;
}

.suibscriptiontext
{
  margin: 5vw;
  text-align: center;
}
.subscriptionwebheading
{
  font-family: "Segoe UI", "Roboto", "Oxygen", "Ubuntu",
  "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  margin-left: 50px;
  text-align: left;
  color: rgba(0, 0, 0, 1);;
  font-size: 55px;
  padding-top: 10vh;
  padding-bottom: 0px;
  text-align: center;
  transition: all ease-in-out .5s;
}
.subscriptionwebheadingcon {

  
  background-color: white;
 
  z-index: 1000;
}